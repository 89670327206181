import $ from "jquery";
import objectFitImages from "object-fit-images";
import svg4everybody from "svg4everybody";
import "lazysizes";
import "picturefill";
import uiHelpers from "./ui-helpers";

svg4everybody();
uiHelpers.init();
objectFitImages();

const spd = 300;
const timeout = spd * 3;
let p = false;

// include any plugin initialisation here
// any custom JS that needs to be written should be modular (and in a separate file), possibly done as a jQuery plugin or some other modular form, and initialised here like the other plugins
// $(".no-js").removeClass("no-js");

// Send scrolling events to Google Analytics http://scrolldepth.parsnip.io/
if (typeof $.scrollDepth === "function") {
  $.scrollDepth();
}

$(document).ready(() => {
  // Display requested content based on URL
  $(".navigation")
    .find(
      "a.js-expand:not([data-id='10139'])[href='" + window.location.href + "']"
    )
    .trigger("click");
});

const baseUrl = window.location.protocol + "//" + window.location.host;

// Navigation
const pages = document.querySelectorAll(".js-expand-page");
const cats = document.querySelectorAll(".js-expand-cat");
const posts = document.querySelectorAll(".js-expand-post");
const categoryGrids = document.querySelectorAll(".js-category");
const links = document.querySelectorAll(
  ".content__inner a:not(.js-expand-post)"
);
const currentPosts = document.querySelectorAll<HTMLElement>(".content--post");

let i: number;

function resetLinks() {
  const elems = document.querySelectorAll(".current");

  [].forEach.call(elems, function(el: any) {
    el.classList.remove("current");
  });
}

// Navigation  - Pages
for (i = 0; i < pages.length; i++) {
  pages[i].addEventListener("click", function(e) {
    const id = this.dataset.id;
    const pageContent = document.getElementById(id);
    const allContent = document.querySelectorAll(".content");

    p = false;

    if (this.parentNode.classList.contains("active")) {
      this.parentNode.classList.remove("active");
      this.classList.remove("current");
      pageContent.style.maxHeight = null;
    } else {
      for (const c of allContent as any) {
        const prev = c as Element;
        prev.previousElementSibling.classList.remove("active");
        resetLinks();
        c.style.maxHeight = null;
      }
      this.parentNode.classList.add("active");
      this.classList.add("current");
      pageContent.style.maxHeight = pageContent.scrollHeight + "px";
    }

    e.preventDefault();
  });
}

// Navigation - Categories
for (i = 0; i < cats.length; i++) {
  cats[i].addEventListener("click", function(e) {
    const id = this.dataset.id;
    const catContent = document.getElementById(id);
    const allContent = document.querySelectorAll(".content");

    p = false;

    for (const c of allContent as any) {
      const prev = c as Element;
      prev.previousElementSibling.classList.remove("active");
      resetLinks();
      c.style.maxHeight = null;
    }

    if (catContent.style.maxHeight) {
      catContent.style.maxHeight = null;
    } else {
      catContent.style.maxHeight = catContent.scrollHeight + "px";
    }

    this.parentNode.classList.toggle("active");
    this.classList.toggle("current");

    e.preventDefault();
  });
}

// Navigation - Posts (Projects)
for (i = 0; i < posts.length; i++) {
  posts[i].addEventListener("click", function(e) {
    const id = this.dataset.id;
    const name = this.dataset.name;
    const href = this.href;

    this.parentNode.parentNode.parentNode.parentNode
      .querySelector(".content--post")
      .setAttribute("id", id);

    const postContent = document.getElementById(id);
    const allContent = document.querySelectorAll(".content");

    for (const c of allContent as any) {
      c.style.maxHeight = null;
    }

    postContent.innerHTML = '<h3 class="post__loader">loading...</h3>';
    postContent.style.maxHeight = postContent.scrollHeight + "px";

    setTimeout(() => {
      fetch(href)
        .then(response => {
          return response.text();
        })
        .then(html => {
          postContent.innerHTML = html;
          setTimeout(() => {
            postContent.style.maxHeight = postContent.scrollHeight + "px";
            p = true;
          }, spd);
        })
        .catch(err => {
          console.warn("Something went wrong...", err);
        });

      window.history.pushState(id, name, href);
    }, timeout);

    e.preventDefault();
  });
}

// Navigation - Posts (prev, next & view all)
document.querySelector("body").addEventListener("click", function(e) {
  if ((e.target as HTMLElement).classList.contains("js-expand-all")) {
    const t = e.target as HTMLElement;
    const cat = t.dataset.cat;
    const content = document.getElementById(cat);
    const allContent = document.querySelectorAll(".content");

    p = false;

    for (const c of allContent as any) {
      const prev = c as Element;
      resetLinks();
      prev.previousElementSibling.classList.remove("active");
      c.style.maxHeight = null;
    }

    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      resetLinks();
      content.parentNode.firstElementChild.classList.add("active");
      content.style.maxHeight = content.scrollHeight + "px";
    }

    window.scrollTo({
      top: 0
    });

    e.preventDefault();
  }

  if ((e.target as HTMLElement).classList.contains("js-expand-prev")) {
    const t = e.target as HTMLElement;
    const post = t.dataset.post;
    const href = t.getAttribute("href");

    window.scrollTo({
      top: 0
    });

    document
      .querySelector(".navigation .content--post")
      .setAttribute("id", post);

    const postContent = document.getElementById(post);
    postContent.innerHTML = '<h3 class="post__loader">loading...</h3>';
    postContent.style.maxHeight = postContent.scrollHeight + "px";

    setTimeout(() => {
      fetch(href)
        .then(response => {
          return response.text();
        })
        .then(html => {
          postContent.innerHTML = html;
          setTimeout(() => {
            postContent.style.maxHeight = postContent.scrollHeight + "px";
            p = true;
          }, spd);
        })
        .catch(err => {
          console.warn("Something went wrong...", err);
        });
    }, timeout);

    e.preventDefault();
  }

  if ((e.target as HTMLElement).classList.contains("js-expand-next")) {
    const t = e.target as HTMLElement;
    const post = t.dataset.post;
    const href = t.getAttribute("href");

    window.scrollTo({
      top: 0
    });

    document
      .querySelector(".navigation .content--post")
      .setAttribute("id", post);

    const postContent = document.getElementById(post);
    postContent.innerHTML = '<h3 class="post__loader">loading...</h3>';
    postContent.style.maxHeight = postContent.scrollHeight + "px";

    setTimeout(() => {
      fetch(href)
        .then(response => {
          return response.text();
        })
        .then(html => {
          postContent.innerHTML = html;
          setTimeout(() => {
            postContent.style.maxHeight = postContent.scrollHeight + "px";
          }, spd);
        })
        .catch(err => {
          console.warn("Something went wrong...", err);
        });
    }, timeout);

    e.preventDefault();
  }
});

// External link handling
const isExternalURL = (function() {
  const domainRe = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i;

  return url => {
    const domain = url => domainRe.exec(url)[1];
    return domain(location.href) !== domain(url);
  };
})();

// Internal link handling
for (i = 0; i < links.length; i++) {
  links[i].addEventListener("click", function(e) {
    const contentLink = this.href;

    if (!isExternalURL(contentLink)) {
      const t = e.target as HTMLElement;
      const post = t.dataset.post;
      const href = t.getAttribute("href");

      let categoryIndex = 0;

      if (contentLink.indexOf("fairly-recent") > -1) {
        categoryIndex = 1;
      } else if (contentLink.indexOf("not-so-recent") > -1) {
        categoryIndex = 2;
      } else if (contentLink.indexOf("jurassic") > -1) {
        categoryIndex = 3;
      }

      const categoryLink = document.querySelector(
        ".navigation li:nth-child(3) header > a:nth-child(" +
          categoryIndex +
          ")"
      ) as HTMLElement;

      categoryLink.click();

      window.scrollTo({
        top: 0
      });

      document
        .querySelector(".navigation .content--post")
        .setAttribute("id", post);

      for (const g of categoryGrids as any) {
        g.style.maxHeight = null;
      }

      const postContent = document.getElementById(post);
      postContent.innerHTML = '<h3 class="post__loader">loading...</h3>';
      postContent.style.maxHeight = postContent.scrollHeight + "px";

      setTimeout(() => {
        fetch(href)
          .then(response => {
            return response.text();
          })
          .then(html => {
            postContent.innerHTML = html;
            setTimeout(() => {
              postContent.style.maxHeight = postContent.scrollHeight + "px";
              p = true;
            }, spd);
          })
          .catch(err => {
            console.warn("Something went wrong...", err);
          });
      }, timeout);

      e.preventDefault();
    }
  });
}

// Redirect work URLs
if (document.referrer.indexOf("work") > -1) {
  const referrer = document.querySelector(
    "a.js-expand-post[href='" + document.referrer + "']"
  ) as HTMLElement;

  window.history.pushState("", "", document.referrer);

  if (referrer != null) {
    referrer.click();
  }
}

// Second pass on height calculation
setInterval(() => {
  if (p === true) {
    currentPosts.forEach(post => {
      if (document.querySelector(".content--post > .post__single") != null) {
        post.style.maxHeight =
          document.querySelector(".content--post > .post__single")
            .scrollHeight + "px";
      }
    });
  }
}, timeout * 2);
