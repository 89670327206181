import $ from "jquery";

function init() {
  // Prevent disabled links/buttons
  $(".btn, a, button, input[type=submit]").click(function(e) {
    if ($(this).hasClass("btn-disabled") || $(this).hasClass("disabled")) {
      e.preventDefault();
      return false;
    }
  });

  // Responsive video wrapper
  $(".iframe[src*='vimeo.com'], .iframe[src*='youtube.com']")
    .not(".js-no-maintain-ratio")
    .each(function() {
      const width = parseInt($(this).attr("width"), 10);
      const height = parseInt($(this).attr("height"), 10);
      const aspectRatio = (height / width) * 100;
      const wrapper = $(
        "<div class='iframe-container iframe-container--maintain-ratio'></div>"
      ).css("padding-bottom", aspectRatio + "%");
      $(this).wrap(wrapper);
    });
}

export default { init };
